






















































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { CategorizedMeds } from '@/util/categorizeMeds'
import Physician from '@/models/Physician'
import Loading from '@/components/Loading.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import MedListItem from '@/components/medications/physicianUpdate/MedListItem.vue'
import DashboardMedication, { Group } from '@/models/DashboardMedication'

@Component({
  components: {
    Loading,
    LinkArrowIcon,
    MedListItem,
  },
  computed: {
    ...mapState('physicianUpdate', ['mainMedication']),
    ...mapGetters('medications', ['medsByPrescriber']),
    ...mapGetters('asyncStatus', ['isLoading', 'isInProgress', 'hasSucceeded']),
  },
})
export default class UpdatePrescriberOtherMeds extends Vue {
  isLoading!: (key: string) => boolean
  isInProgress!: (key: string) => boolean
  hasSucceeded!: (key: string) => boolean
  medsByPrescriber!: CategorizedMeds<Physician>[]
  mainMedication!: DashboardMedication

  get oldPhysician() {
    return this.mainMedication.physician
  }

  get allMedsWithPhysician() {
    if (!this.oldPhysician) return []
    const category = this.medsByPrescriber.find(
      category => category.id === this.oldPhysician!.categoryId,
    )
    return (category?.meds || []).filter(med => med.group === Group.Active)
  }

  get otherMedsWithPhysician() {
    return this.allMedsWithPhysician.filter(med => med.id !== this.mainMedication.id)
  }

  get loading() {
    return (
      this.isLoading('medications/load') || this.isInProgress('physicianUpdate/selectPhysician')
    )
  }

  updateAll() {
    this.$store.commit('physicianUpdate/setOtherMedications', this.otherMedsWithPhysician)
    this.$router.push({ path: 'confirm' })
  }

  updateOne() {
    this.$store.commit('physicianUpdate/setOtherMedications', [])
    this.$router.push({ path: 'confirm' })
  }

  async created() {
    if (!this.hasSucceeded('medications/load')) {
      await this.$store.dispatch('medications/load')
    }
    if (!this.otherMedsWithPhysician.length) {
      this.$router.replace({ path: 'confirm' })
    }
  }
}
