














































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Physician from '@/models/Physician'
import Loading from '@/components/Loading.vue'
import SignupPhysicianCard from '@/components/medications/SignupPhysicianCard.vue'
import MedListItem from '@/components/medications/physicianUpdate/MedListItem.vue'
import DashboardMedication from '@/models/DashboardMedication'
import { PrescriberSearchQuery } from '@/vuex/physicians'
import { BannerInfo } from '@/vuex/banners'

@Component({
  components: {
    Loading,
    SignupPhysicianCard,
    MedListItem,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded']),
    ...mapState('physicians', ['searchQuery']),
    ...mapState('physicianUpdate', ['physician', 'mainMedication', 'otherMedications']),
  },
})
export default class PrescriberConfirm extends Vue {
  isInProgress!: (key: string) => boolean
  hasSucceeded!: (key: string) => boolean
  physician!: Physician
  mainMedication!: DashboardMedication | null
  otherMedications!: DashboardMedication[]
  searchQuery!: PrescriberSearchQuery

  get previousPhysician() {
    return this.mainMedication?.primaryPrescription?.physician
  }

  get selectedMedications() {
    return [this.mainMedication, ...this.otherMedications].filter(Boolean)
  }

  showSuccessBanner() {
    const banner: BannerInfo = {
      id: 'update-physician-success',
      bgVariant: 'primary',
      title: this.$t('Thank you!'),
      message: this.$t('updateDoctor.banner.successMessage'),
    }
    this.$store.dispatch('showBanner', banner)
  }

  showErrorBanner() {
    const banner: BannerInfo = {
      id: 'update-physician-error',
      bgVariant: 'danger',
      title: this.$t('An error occurred'),
      message: this.$t('updateDoctor.banner.errorMessage'),
    }
    this.$store.dispatch('showBanner', banner)
  }

  async submitRequest() {
    const physician = this.physician
    const prescription = this.mainMedication?.primaryPrescription
    const otherPrescriptions = this.otherMedications.map(med => med.primaryPrescription)

    if (!prescription) {
      throw new Error('Unable to submit request, main prescription not set')
    }

    if (!physician || !physician.id) {
      throw new Error('Unable to submit request, physician not set')
    }

    await this.$store.dispatch('physicianUpdate/sendRequest', {
      prescription,
      physician,
      location: this.searchQuery.location,
      otherPrescriptions,
    })

    if (this.hasSucceeded('physicianUpdate/sendRequest')) {
      this.showSuccessBanner()
    } else {
      this.showErrorBanner()
    }

    this.$router.push({ name: 'medication-details' })
  }
}
