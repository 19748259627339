



















































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Physician from '@/models/Physician'
import Loading from '@/components/Loading.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import MedListItem from '@/components/medications/physicianUpdate/MedListItem.vue'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import DashboardMedication from '@/models/DashboardMedication'

@Component({
  components: {
    Loading,
    LinkArrowIcon,
    MedListItem,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded', 'getError']),
    ...mapGetters('medications', ['getMedication']),
    ...mapState('physicians', ['physicians']),
  },
})
export default class PrescriberSearch extends Vue {
  hasSucceeded!: (key: string, id?: string) => boolean
  isInProgress!: (key: string, id?: string) => boolean
  physicians!: Physician[]
  medStrength!: PrescriptionStrength
  getMedication!: (id: string) => DashboardMedication | null

  get medication(): DashboardMedication | null {
    const { id } = this.$route.params
    return this.getMedication(id)
  }

  get otherPhysicians() {
    return this.physicians.filter(p => p.id !== this.medication?.physician?.id)
  }

  selectPrescriber(physician: Physician) {
    this.$store.dispatch('physicianUpdate/selectPhysician', physician)
    this.$router.push({ name: 'update-prescriber-other-meds' })
  }

  async setMainMedication() {
    if (!this.medication) {
      await this.$store.dispatch('medications/loadOne', this.$route.params)
    }

    this.$store.commit('physicianUpdate/setMainMedication', this.medication)
  }

  async created() {
    this.setMainMedication()

    await this.$store.dispatch('physicians/load')
    if (this.hasSucceeded('physicians/load')) {
      if (!this.otherPhysicians.length) {
        this.$router.replace('search')
      }
    }
  }
}
