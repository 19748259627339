import { render, staticRenderFns } from "./UpdatePrescriberOtherMeds.vue?vue&type=template&id=12237be2&scoped=true&"
import script from "./UpdatePrescriberOtherMeds.vue?vue&type=script&lang=ts&"
export * from "./UpdatePrescriberOtherMeds.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12237be2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCollapse } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
installComponents(component, {BCollapse,BLink,BListGroup,BListGroupItem})
