













import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardMedication from '@/models/DashboardMedication'
import MedImage from '@/components/medications/MedImage.vue'

@Component({
  components: {
    MedImage,
  },
})
export default class MedListItem extends Vue {
  @Prop(Object)
  medication!: DashboardMedication
}
